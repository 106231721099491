import React from "react"
import Layout from "../layout";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col"


import GenericPage from "../components/generic-page";

// markup
export default function About() {
  return (<GenericPage title={"About SNPhotography"}>    
    <div>{`Sharon Newman is the director and Professional Photographer behind SNPhotography. SNPhotography has provided a professional, high standard of photography for going on 13 years. Sharon is a high caliber, multi skilled photographer, that provides unique and reliable services to a broad range of clientele. Education and Commitment: Sharon has a Diploma in Photo-imaging acquired through TAFE and she has been a member of the Australian Institute of Professional Photography for the past 11 years.`}</div>
    <div>
      <h2>{"Services Provided"}</h2>
      <ul>
        <li>{"High quality real estate, architectural"},</li>
        <li>{"interior styling photography"}</li>
        <li>{"Powerful commercial and family portraiture"}</li>
      </ul>
    </div>
    <div>
      <h2>{"Other Info"}</h2>
      <ul>
        <li>{"Multiple websites utilise Sharon's work as a great marketing tool"},</li>
        <li>{"SNPhotography's website plays an integral role in providing fast efficient download of images to service all clientele."}</li>
        <li>{"We also provide on the spot styling advise"}</li>
        <li>{"We are the first port of call for agents working toward selling homes effectively"}</li>
        <li>{"We have provided innumerable images to an unknown amount of clients"}</li>
      </ul>
    </div>
    <div>
      <h2>{"Awards and Accolades"}</h2>
      <ul>
        <li>{"Sharon has won numerous awards for Portraiture"},</li>
        <li>{"New York based Exhibition with SEE.Me."}</li>
        <li>{"SNPhotography won the Professional Services' award in 2016 through the Penrith Local Business Awards "}</li>
        <li>{"We are the first port of call for agents working toward selling homes effectively"}</li>
        <li>{"We have provided innumerable images to an unknown amount of clients"}</li>
      </ul>
    </div>
    <div>
      {"Do not Hesitate to Contact Us with your photographic Needs"}
    </div>
  </GenericPage>);
}

